import React from 'react'
import { Link } from 'gatsby'
import { checkValidEmail } from '../utils/auth'
import { Container } from 'react-bootstrap'

const success = () => (
    <>
        <h1>Email confirmé !</h1> <Link to='/'>Retourner à la page d'accueil pour se connecter</Link>
    </>
)

const error = () => (
    <>
        <h1>Erreur lors de la validation de votre email, veuillez nous contacter afin de résoudre le probleme</h1> <Link to='/'>Retourner à la page d'accueil</Link>
    </>
)

export default ({location}) => {
    return (
        <Container className="text-center">
            <div className="mt-4">
                { checkValidEmail(location.search) ? success() : error()}
            </div>
        </Container>
    )
}